<template>
	<div>
		<keep-alive>
			<component
				v-bind:is="activeNavItem"
				transition="fade-transition"
			></component>
		</keep-alive>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
	name: "PrincipalView",
	data(){
		return {
		}
	},
	components: {},
	computed: {
		...mapState("ui", {
			activeNavItem: (state) => state.navigation.itemActive,
			selectedItem: (state) => state.navigation.IndexItemActive,
		}),
	},
	mounted() {
        // Activar los elementos
        this.enableddNavigation()

		// =>
		this.disabledBackBtn();

		// Clear items
		this.clearNavigationItems();
    
        // Agregar los items
		this.addNavigationItem({
			name: this.$t('global.navigation.txtServices'),
			icon: "mdi-car",
			type: "component",
			component: () =>
				import(
					/* webpackChunkName: "services" */ "@/components/services.vue"
				),
		});
		this.addNavigationItem({
			name: this.$t('global.navigation.txtHistory'),
			icon: "mdi-history",
			type: "component",
			component: () =>
				import(
					/* webpackChunkName: "binnacle" */ "@/components/binnacle.vue"
				),
		});
		this.addNavigationItem({
			name: this.$t('global.navigation.txtChecking'),
			icon: "mdi-qrcode-scan",
			type: "action",
			action: () => {
				this.resetQR();
				this.openQRReader();
			},
		});
		// Seleccionar el elemento 0
		this.selectNavigationItem(this.selectedItem);

		// Validar si se entro para abrir el QRReader
		if (this.$router.currentRoute.name === 'QRReader') {
			this.openQRReader();
		}
	},
	methods: {
		...mapMutations("ui", {
			addNavigationItem: "addNavigationItem",
			selectNavigationItem: "selectNavigationItem",
			clearNavigationItems: "clearNavigationItems",
		}),
		...mapActions("ui", {
			enableddNavigation: "enableddNavigation",
			openQRReader: 'openQRReader',
			disabledBackBtn: 'disabledBackBtn',
			resetQR: 'resetQR',
		}),
	},
};
</script>
